.highlight {
  --default-foreground: #e8e8d3;
  --keyword: #8fbfdc;
  --keyword-light: #6dc8ff;
  --type: #f4bf75;
  --function: #cdd3f8;
  --string-lit: #99ad6a;
  --punctuation: #75715e;
  --key: #66d9ef;

  color: var(--default-foreground);
  background-color: var(--background-light);

  .w {
    color: #f8f8f2;
    background-color: #272822;
  }

  .err {
    color: #272822;
    background-color: #f92672;
  }

  .c,
  .ch,
  .cd,
  .cm,
  .cpf,
  .c1,
  .cs,
  .p {
    color: var(--punctuation);
  }

  .cp,
  .nt {
    color: #f4bf75;
  }

  .o,
  .ow,
  .pi {
    color: #f8f8f2;
  }

  .gi {
    color: #a6e22e;
  }

  .gd {
    color: #f92672;
  }

  .gh {
    color: #66d9ef;
    background-color: #272822;
    font-weight: bold;
  }

  .k,
  .kn,
  .kp,
  .kr,
  .kv {
    color: #ae81ff;
  }

  .kc,
  .kt,
  .kd {
    color: #fd971f;
  }

  .s,
  .sb,
  .sc,
  .dl,
  .sd,
  .s2,
  .sh,
  .sx,
  .s1 {
    color: #a6e22e;
  }

  .sa {
    color: #ae81ff;
  }

  .sr {
    color: #a1efe4;
  }

  .si,
  .se {
    color: #cc6633;
  }

  .nn,
  .nc,
  .no,
  .nb {
    color: var(--type);
  }

  .nx,
  .nf {
    color: var(--function);
  }

  .na {
    color: var(--key);
  }

  .m,
  .mb,
  .mf,
  .mh,
  .mi,
  .il,
  .mo,
  .mx,
  .ss {
    color: #a6e22e;
  }
}
