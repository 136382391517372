@use "sass:color";

$base-color: #0a0c20;
$lighter-color: color.adjust($base-color, $lightness: +10%);
$lightest-color: color.adjust(
  $base-color,
  $lightness: +55%,
  $hue: -45deg,
  $saturation: +100%
);

:root {
  // Font sizes
  --step-0: clamp(1.125rem, 1.0815rem + 0.2174vw, 1.25rem);
  --step-1: clamp(1.35rem, 1.2761rem + 0.3696vw, 1.5625rem);
  --step-2: clamp(1.62rem, 1.5041rem + 0.5793vw, 1.9531rem);
  --step-3: clamp(1.9438rem, 1.7707rem + 0.8652vw, 2.4413rem);
  --step-4: clamp(2.3325rem, 2.0823rem + 1.2511vw, 3.0519rem);
  --step-5: clamp(2.7994rem, 2.4461rem + 1.7663vw, 3.815rem);
  --step-6: clamp(3.3594rem, 2.8694rem + 2.45vw, 4.7681rem);
  --step-7: clamp(4.0313rem, 3.3602rem + 3.3554vw, 5.9606rem);

  // Colors
  --maximum-yellow-red: #e3b03b;
  --beige: #eeecd8;
  --background: #0f0c08;
  --background-light: #151515;

  --ng-base: #{$base-color};
  --ng-lighter: #{$lighter-color};
  --ng-lightest: #{$lightest-color};

  --test-maximum-yellow-red: #46ccf5;
  --test-background: #0a0c20;
  --test-background-light: #181b43;

  // Lines
  --line-width: 3px;
}

// Some test
// --maximum-yellow-red: #46ccf5;
// --background: #0a0c20;
// --background-light: #181b43;

// #034F44
// #087B79
// #10A4A7
// #1AB1D3
// #26B4FB
// #6EFDF8
// #B7FEE2
