:root {
  background-color: var(--background);
  color: var(--beige);
  font-family: sans-serif;
  font-size: var(--step-0);
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.hstack {
  display: flex;
  flex-flow: row wrap;
}

.vstack {
  display: flex;
  flex-flow: column wrap;
}

.gap-1 {
  gap: 1em;
}

.center-align {
  align-items: center;
}

.navigation {
  justify-content: space-between;
  width: 100%;
  padding-block: 0.5em;
  padding-inline: 1em;
  background-color: var(--background-light);

  a#branding {
    color: var(--beige);
  }

  @media (max-width: 28.5rem) {
    justify-content: center;
  }
}

@supports (-webkit-touch-callout: none) {
  body {
    min-height: -webkit-fill-available;
  }
}

hr {
  border: none;
  height: var(--line-width);
  background: var(--maximum-yellow-red);
}

a {
  color: var(--maximum-yellow-red);
}

p {
  margin-bottom: 1em;

  & > code {
    color: var(--beige);
    background-color: var(--background-light);
    padding: 0.2em;
    border-radius: 0.3em;
  }
}

main {
  padding-top: 1em;
  max-width: 80ch;
  width: 100%;
  padding-inline: 1em;
}

img.brand-image {
  height: 2.5em;
  width: auto;
}

article {
  margin-bottom: 4em;

  pre,
  code {
    font-family: ui-monospace, 'Cascadia Code', 'Source Code Pro', Menlo, Consolas, 'DejaVu Sans Mono', monospace;
  }

  & > header {
    position: relative;
    margin-bottom: 1.2em;
  }

  & > header::after {
    content: "";
    position: absolute;
    background-color: var(--maximum-yellow-red);
    height: var(--line-width);
    width: 100%;
    bottom: -0.6em;
    left: 0;
  }

  .highlighter-rouge > .highlight {
    border-radius: 0.3em;

    pre {
      overflow-x: auto;
      padding: 0.3em 0.7em;
      border-radius: 0.3em;
    }
  }

  h1 {
    font-size: var(--step-4);
  }

  h2 {
    font-size: var(--step-3);
  }

  h3 {
    font-size: var(--step-2);
  }

  h2 {
    font-size: var(--step-1);
  }
}

:is(h1, h2, h3, h4) > a {
  color: var(--beige);
  text-decoration: none;
}

ul {
  list-style: none inside;
  text-align: center;
  margin: 0;
  padding: 0;
}
